<script>
import {computed, defineComponent, onBeforeUnmount, ref, watch} from 'vue';
import {useStore} from '@/store';
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import DynamicTestInfoView from '@/components/dynamic/testInfoView.vue';
import TestInfoReloader from '@/components/dynamic/testInfoReloader.vue';
import requests from '@/js/requests';
import {useRoute} from 'vue-router/composables';

export default defineComponent({
  name: 'TestInfoView',
  components: {
    TestInfoReloader,
    PhpServiceView,
    DynamicTestInfoView,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    let cSpace = {};
    const newResult = ref(null);

    const updateStoreTable = (result) => {
      cSpace = result.valueLine.c_space;
      store.commit('tableChanged', result.table);
    };

    const unsubscribe = store.subscribe((mutation) => {
      if (mutation.type === 'resultChanged') {
        newResult.value = mutation.payload;
      }
    });

    onBeforeUnmount(() => {
      unsubscribe();
    });

    const testInfoAdminInfo = computed(() => store.state.testInfoAdminInfo);

    watch(testInfoAdminInfo, () => {
      console.log('Updating!');
      requests.frameworkAxiosRequest({
        method: 'post',
        url: `serve.php?f=testing&f2=testInfo&function=getInfo`,
        data: {
          n_id: route.params.id,
          n_id_key: route.params.idKey,
          requestType: 'ajax',
          excludeRows: [],
          showInfo: store.state.testInfoAdminInfo,

          ...cSpace,
        },
      })
    });

    return {
      updateStoreTable,
      newResult,
    };
  },
});
</script>

<template>
  <PhpServiceView
      :url="`?f=testing&f2=testInfo&function=getInfo&n_id=${$route.params.id}&n_id_key=${$route.params.idKey}`"
      @result="updateStoreTable"
      #default="{result}">
    <DynamicTestInfoView :result="newResult ? newResult : result"/>
    <TestInfoReloader
        v-if="(newResult ? newResult : result).content.includes('testInfoReloader')"
        :result="newResult ? newResult : result"/>
  </PhpServiceView>
</template>

<style scoped>

</style>